import _ from 'lodash';

// used to force cleaning cache, to fix a problem with low candle count after browser refresh

let counter = 0
let prevUid = ''

export default function createStream (socket) {
  let _subs = [];
  let currentSub = '';
  let timer = null;
  let pairChanged = true

  socket.on('connect', function () {
    _subs.forEach(sub => {
      sendSubRequestEvent({ sub: sub.channelString, from: 0 });
    });
  });

  function sendSubRequestEvent({ sub, from }) {
    currentSub = sub;
    pairChanged = true
    const fromValue = from || 0
    socket.emit('TVSub', { sub, fromValue });

    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      sendSubRequestEvent({ sub, from });
    }, 50);

    socket.once(`TVSub:${sub}`, function () {
      clearTimeout(timer);
      timer = null;

    });
  }

  return {
    subs: _subs,
    sendSubRequestEvent: sendSubRequestEvent,
    subscribeBars(symbolInfo, resolution, updateCb, uid, resetCache) {
      symbolInfo.description = 'GUNTHY - v28.8.8';
      const channelString = createChannelString(symbolInfo);

      if (prevUid !== channelString) {
        const subIndex = _subs.findIndex(e => e.uid === uid)
        if (subIndex < 0) {
          const newSub = {
            channelString,
            uid,
            resolution,
            symbolInfo,
            lastBar: { time: 0 },
            listener: updateCb,
            reset: resetCache,
          };

          _subs.push(newSub);

          const fromTime = pairChanged ? 0 : newSub.lastBar.time
          sendSubRequestEvent({ sub: channelString, from: fromTime });
          if (pairChanged) {
            pairChanged = false
          }

          counter++
          prevUid = channelString
        }
      }
    },
    unsubscribeBars(uid) {
      const subIndex = _subs.findIndex(e => e.uid === uid);
      if (subIndex === -1 || _subs.length <= 1) {
        return;
      }
      _subs.splice(subIndex, 1);
    },
    updateCb(params) {
      if (_.isNil(params)) {
        return
      }
      let channelString = currentSub,
        klines = [],
        candles = [];

      if (params) {
        const { exchange, pair, data = [] } = params;
        channelString = createChannelString({ exchange, pair });
        candles = data;
      }
      const sub = _subs.find(e => e.channelString === channelString);
      if (sub) {
        /*
        if (candles.length === 0) {
          // this possibly causes the dildo charts
          if (sub && sub.lastBar) {
            klines = [sub.lastBar];
          }
        } 
        else {
          klines = candles.map(kline => {
            return {
              time: kline[0],
              close: parseFloat(kline[4]),
              open: parseFloat(kline[1]),
              high: parseFloat(kline[2]),
              low: parseFloat(kline[3]),
              volume: parseFloat(kline[5]),
            };
          });
        }
        */
        klines = candles.map(kline => {
          return {
            time: kline[0],
            close: parseFloat(kline[4]),
            open: parseFloat(kline[1]),
            high: parseFloat(kline[2]),
            low: parseFloat(kline[3]),
            volume: parseFloat(kline[5]),
          };
        });

        // vars useful for debugging
        let leftCount = klines.length
        let returnCount = 0
        let placedCount = 0

        klines.forEach((kline, index) => {
          if (kline.time < sub.lastBar.time ) {
            returnCount ++
            return;
          }
          try {
            sub.listener(kline);
            placedCount ++
            leftCount--
          } catch (e) {
            // do nothing
          }

          sub.lastBar = kline;
        });
      }
      if (!_.isNil(sub)) {
        return sub
      }
    },
  };
}

function createChannelString(symbolInfo) {
  return `${symbolInfo.exchange}:${symbolInfo.pair}`;
}
